<template>
  <p><!-- Nada por aqui --></p>
</template>

<script>
export default {
  name: "RouterRole",
  mounted() {
    const user = this.$store.getters.getUser;
    console.log(user);
    const role = user.role

    if (role == 'admin') {
      this.$router.push({ name: 'Administrador' })
    }else if (role == 'cedis'){
      this.$router.push({ name: 'Iniciocedis' })
    }else if (role == 'client'){
      this.$router.push({ name: 'Archivos' })
    }else if (role == 'collection'){
      this.$router.push({ name: 'Iniciocobranza' })
    }
  }
}
</script>

<style>

</style>